body{
    font-family: 'Roboto', sans-serif;
}
/* Header Style */

.header-main{
    width:100%;
    background: #012025;
    height:80px;
    padding:0px 30px;
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.signin-box button{
    border: 2px solid #27AA8A;
    border-radius: 2px;
    Width:81px;
    Height:34px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #27AA8A;
    background: transparent;
}
.signin-box button.skip{
    border: none;
    border-radius: 2px;
    Width:81px;
    Height:34px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #27AA8A;
    background: transparent;
}
/* Footer Style */

.footer{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height:100px;
    background: #012025;
    padding:0 30px;
}
.links{
    display: flex;
    align-items: center;
}
.links a{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    padding-right:20px;
    text-decoration: none;
}
.social-links{
    display: flex;
    align-items: center;
}
.social-links a{
    padding: 0 10px;
}
.copy-right span{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

/* Fixed Content Style */

.fixed-section{
    width:100%;
    float:left;
    padding:80px 0;
    background: #ffffff;
}
.fixed-box{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.fixed-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right:40px;
}
.content-box h5{
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 34px;
    color: #012025;
    margin-bottom: 15px;
}
.content-box p{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #012025;
    margin: 0;
}
.fixed-right{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.seprater{
    display: none;
}

/* Banner Section Style */

.banner-section{
    width:100%;
    float:left;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    height:700px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
.baner-overlay{
    width:100%;
    background: #012025;
    border-radius:0px;
    float:left;
    display: flex;
    align-items: center;
    justify-content: center;
    height:343px;
}
.banner-form-content {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
}
.banner-form{
    width:359px;
    background:#ffffff;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
}
.banner-form h3{
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #313131;
    margin:15px 0;
}
.banner-content-box {
    width: 70%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 80px;
}
.banner-content-box h4{
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 50px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 20px;
}
.banner-content-box span{
    color:#4AA28C;
}
.banner-content-box ul{
    padding: 0 20px;
    margin:0;
}
.banner-content-box ul li{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 42px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}
.form-soical-buttons {
    width: 100%;
    text-align: center;
}
button.facebook-buton{
    background: #1877F2;
    border-radius: 2px;
    height:44px;
    border:none;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    width:100%;
    padding: 0 12px;
}
button.facebook-buton img{
    width:20px;
}
button.facebook-buton span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #FFFFFF;
    width: 100%;
}
button.google-buton{
    background: #ffffff;
    border-radius: 2px;
    height:44px;
    border: 1px solid #A9A9A9;
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    padding: 0 12px;
}
button.google-buton img{
    width:20px;
}
button.google-buton span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54);
    width: 100%;
}
.Seperater-social{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:15px;
}
.Seperater-social span{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #555555;
    padding: 0 10px;
}
.banner-content-box.for-mobile{
    display: none;
}

/* Form Style */

.login-form {
    width: 100%;
    float: left;
    margin-top: 20px;
}
.simple-input{
    margin-bottom: 10px;
}
.login-form .form-control{
    background: #F6F8FB;
    border: 1px solid #D8D8D8;
    box-shadow: inset 0px 3px 4px rgba(204, 204, 204, 0.05);
    border-radius: 2px;
    height:44px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #625C6C;
}
.login-form .form-control.error{
    background: #F6F8FB;
    border: 1px solid #EB5757;
    box-shadow: inset 0px 3px 4px rgba(204, 204, 204, 0.05);
    border-radius: 2px;
    height:44px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #625C6C;
}

.login-form .form-control::-webkit-input-placeholder { /* Edge */
    color: #625c6c96;
    font-weight: 300;
    font-size: 13px;
}
.login-form .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #625c6c96;
    font-weight: 300;
    font-size: 13px;
}
.login-form .form-control::placeholder {
    color: #625c6c96;
    font-weight: 300;
    font-size: 13px;
}
.login-form  button{
    background: #4AA28C;
    border-radius: 2px;
    border:none;
    height:44px;
    width:100%;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color:#ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
span.validation-error {
    color: #EB5757;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    padding-left: 10px;
}

/* Payment Section Style */
.banner-section.payment-web {
    display: flex !important;
}
.payment-for-mobile{
    display: none !important;
}
.payment-section-web {
    width: 45%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.payment-overlay{
    width:100%;
    height:100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
.payment-header {
    width: 100%;
    background: #012025;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 4px 0px 0px;
    height: 60px;
}
.payment-header  h4{
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 0px;
}
.payment-header span{
    color:#4AA28C;
}
.payment-content-box{
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 4px 4px;
}
.payment-content {
    width: 500px;
    margin: 15px auto 35px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.payment-content img{
    width:100%;
}
.payment-content ul{
    padding:0 15px;
    margin:30px 0 0 0;
}
.payment-content ul li{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #101010;
    padding-bottom: 15px;
}
.payment-plan{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}
.payment-amounts{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 18px;
    width: 100%;
}
.payment-amounts span{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
}
.payment-border{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:16px;
}
.payment-border img{
    width:100%;
}
.payment-total-amount{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:20px;
}
.payment-total-amount span{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #212121;
}
.estimate-value{
    width:100%;
    float:left;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #212121;
    margin-top:16px;
}
.pay-now-button{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:30px;
}
.pay-now-button button{
    width:405px;
    height:52px;
    background: #4AA28C;
    border-radius: 2px;
    margin:0 auto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color:#ffffff;
    border:none;
}
.suppoted-by{
    display: flex;
    align-items: center;
}
.suppoted-by span{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    text-decoration: none;
    padding-right: 6px;
}
.suppoted-by img.chitkara-main{
width:90px;
}