@media only screen  and (max-width: 1340px) {
    .banner-content-box h4 {
        font-size: 34px;
        line-height: 44px;
        margin-bottom: 11px;
    }
    .banner-content-box ul li{
        font-size: 14px;
        line-height: 30px;
    }
    .payment-content{
        width:390px;
    }
}
@media only screen  and (max-width: 991px) {
    .header-main{
        padding: 0 20px;
    }
    .footer{
        justify-content: space-between;
        flex-direction: column;
        padding:20px 20px;
        height:auto;
    }
    .links{
        margin-bottom: 15px;
        flex-direction: column;
    }
    .suppoted-by {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin:15px 0;
    }
    .suppoted-by span{
        padding-right:0;
        padding-bottom: 7px;
    }
    .social-links{
        margin-bottom: 15px;
    }
    .fixed-section{
        background: #1D1D1F;
        padding: 30px 20px;
    }
    .fixed-box{
        flex-direction: column;
    }
    .fixed-left{
        flex-direction: column;
        padding-right: 0;
    }
    .fixed-right{
        flex-direction: column;
    }
    .content-box h5{
        color:#ffffff;
        text-align: center;
        margin-bottom: 25px;
    }
    .content-box p{
        color:#ffffff;
        text-align: center;
    }
    .fixed-left img{
        margin-top:30px;
        width:100%;
    }
    .fixed-right img{
        margin-top:30px;
        width:100%;
    }
    .seprater{
        display: flex;
        margin-top: 24px;
    }
    .seprater img{
        margin-bottom: 20px;
    }
    .banner-section{
        background:none !important;
        flex-direction: column;
        height:auto;
    }
    .baner-overlay{
        flex-direction: column;
        padding: 30px 20px;
        height:auto;
        border-radius:0px;
    }
    .banner-form{
        background:transparent;
        padding: 0;
        width:100%;
    }
    .banner-form img{
        width:100%;
    }
    .banner-content-box{
        display: none;
    }
    .banner-content-box.for-mobile{
        display: flex;
        background: #E5E5E5;
        width:100%;
        padding: 30px ;
    }
    .banner-content-box h4{
        color: #101010;
        font-size:26px;
        line-height: 35px;
    }
    .banner-content-box ul li{
        color: #101010;
        font-size: 15px;
        line-height: 20px;
        padding-bottom: 15px;
    }
    .banner-form h3{
        text-align: center;
        color:#ffffff;
    }
    .banner-section.payment-web {
        display: none !important;
    }
    .payment-for-mobile{
        display: block !important;
        width:100%;
        float:left;
    }
    .payment-mobile-header{
        width:100%;
        background: #E5E5E5;
        padding: 25px 25px;
    }
    .payment-mobile-header h4{
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 0.03em;
        color: #101010;
        margin:0;
    }
    .payment-mobile-header span{
        color:#4AA28C;
    }
    .payment-mobile-header img{
        margin-top:26px;
        width:100%;
    }
    .mobile-payment-amounts{
        width:100%;
        float:left;
        padding:0 25px 25px 25px;
        background: #05282E;
    }
    .amount-box{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .amount-col{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-top:25px;
    }
    .amount-col span{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
    }
    .payment-total-amount span{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
    }
    .estimate-value{
        color:#ffffff;
    }
    .pay-now-button{
        float:left;
    }
    .pay-now-button button{
        width:100%;
    }
    .list-points{
        width:100%;
        background: #E5E5E5;
        padding: 25px;
        float:left;
    }
    .list-points ul{
        margin:0;
        padding:0 15px;
    }
    .list-points ul li{
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #101010;
        padding-bottom: 15px;
    }
}